<template>
  <div class="main">
    <div class="main_box" v-loading="loading">
      <div class="main_form">
        <div class="main_form_list">
          <div class="flex_center main_form_list_item" v-if="districtList.length">
            <div class="main_form_list_item_title">选择地区：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="index === districtItem ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in districtList" :key="item.id" @click="onTime(item, index, 'districtList')">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex_center main_form_list_item" v-if="institutionList.length">
            <div class="main_form_list_item_title">发布机构：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="index === institutionItem ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in institutionList" :key="item.id" @click="onTime(item, index, 'institutionList')">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex_center main_form_list_item" v-if="priceList.length">
            <div class="main_form_list_item_title">价格地区：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="item.name === priceItem ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in priceList" :key="item.id" @click="onTime(item, index,'priceList')">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="flex_center main_form_list_item">
            <div class="main_form_list_item_title">造价种类：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="item.value === kindItem ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in kindList" :key="item.id" @click="onTime(item, 'kindList')">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>-->
          <div class="flex_center main_form_list_item" v-if="timeList.length">
            <div class="main_form_list_item_title">发布时间：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="item === timeItem ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in timeList" :key="index" @click="onTime(item, index, 'timeList')">
                  <span>{{item}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex_center main_form_list_item" v-if="materialsList.length">
            <div class="main_form_list_item_title">材料分类：</div>
            <div class="flex main_form_list_item_center">
              <div class="flex main_form_list_item_center_margin">
                <div :class="item.name === materialsItem ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in materialsList" :key="item.value" @click="onTime(item, index, 'materialsList')">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex_center main_form_list_item" style="padding: 0;">
            <div class="main_form_list_item_title">关键字：</div>
            <div class="flex main_form_list_item_center">
              <el-input v-model="journalName" placeholder="输入关键字搜索"></el-input>
              <el-button type="primary" style="margin-left: 40px;" @click="onSelect">搜 索</el-button>
            </div>
          </div>
        </div>
      </div>
      <template v-if="tableList.length !== 0">
        <div class="main_table">
          <div class="flex_center main_table_item" v-for="(item, index) in tableList" :key="index">
            <div class="main_table_item_l">
              <h3 class="main_table_item_l_title">{{item.journalName}}</h3>
              <p>地区：{{item.city}}</p>
              <p>时间：{{item.updateDate || '-'}}</p>
            </div>
            <div class="main_table_item_r" @click="onPeriodical(item)">查看详情</div>
          </div>
        </div>
        <div class="flex main_pagination">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="size"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </template>
      <template v-else>
          <el-empty :image="require('@/assets/images/bjtsy/no_data.png')" :image-size="400"></el-empty>
      </template>
    </div>
  </div>
</template>

<script>
  import cache from "@/libs/util.cache.js";
  import { urlProjectPriceInfoEsPriceInfo } from '@/api/engineering'
  import { urlColumnList } from '@/api/account'
  export default {
    name: "referencePrice",
    data() {
      return {
        columnList: [],
        districtList: [],
        districtItem: 0,
        institutionList: [],
        institutionItem: '',
        priceList: [],
        priceItem: '',
        kindList: [],
        kindItem: '',
        timeList: [],
        timeItem: '',
        materialsList: [],
        materialsItem: '',
        journalName: '',
        tableList: [],
        size: 20,
        total: 0,
        currentPage: 1,
        loading: false
      }
    },
    async mounted() {
      await this.getColumnList();
      await this.getProjectPriceInfoEsPriceInfo()
    },
    methods: {
      handleCurrentChange(val) {
        this.currentPage = val
        this.getProjectPriceInfoEsPriceInfo()
      },
      onPeriodical(item) {
        cache.setSessionStorage('infoPrice', item);
        this.$router.push({path: "/info-price/periodical"});
      },
      onSelect() {
        this.currentPage = 1
        this.getProjectPriceInfoEsPriceInfo()
      },
      async getProjectPriceInfoEsPriceInfo() {
        this.loading = true
        const param = {
          province: this.districtList[this.districtItem]?.name === '全部' ? '' : this.districtList[this.districtItem]?.name,
          county: this.priceItem,
          current: this.currentPage,
          journalName: this.journalName,
          mouthOrQuarter: "",
          city: this.institutionList[this.institutionItem]?.name,
          size: 10,
          type: "市场参考价",
          type1: this.materialsItem,
          year: this.timeItem
        }
        const res = await urlProjectPriceInfoEsPriceInfo(param)
        const { records, size, total } = res.data
        this.tableList = records
        this.size = size
        this.total = total
        this.loading = false
      },
      getTimeList() {
        this.timeList = this.columnList.filter(item => item.pid === this.districtList[this.districtItem]?.id && item.symbol === "month")
        if (this.timeList.length) {
          let list = new Set(this.timeList.map(item => item.name.split("-")[0]))
          this.timeList = [...list]
        }
      },
      getInstitutionList() {
        this.districtList = [{name: '全部', id: ''}].concat(this.columnList.filter(item => item.pid === 0 && item.symbol === "area"))
        if (this.districtList && this.districtList.length) {
          //信息价
          // this.institutionList = this.columnList.filter(item => item.pid === this.districtList[this.districtItem].id && item.symbol === "priceType")
          this.institutionList = this.columnList.filter(item => item.pid === this.districtList[this.districtItem]?.id && item.symbol === "area")
          this.priceList = this.columnList.filter(item => item.pid === this.institutionList[this.institutionItem]?.id && item.symbol === "area")
          this.materialsList = this.columnList.filter(item => item.pid === this.districtList[this.districtItem]?.id && item.symbol === "material")
          // this.kindList = this.columnList.filter(item => item.pid === this.districtList[this.districtItem]?.id && item.symbol === "material")
          this.getTimeList()
        }
      },
      async getColumnList() {
        this.loading = true
        const param = `appid=wx7ba99686668dd157`
        const res = await urlColumnList(param)
        this.columnList = res.data
        this.getInstitutionList()
      },
      onTime(item, index, type) {
        switch (type) {
          case 'districtList':
            if (this.districtItem === index) return;
            this.districtItem = index;
            this.institutionItem = ''
            this.priceItem = ''
            this.timeItem = ''
            this.materialsItem = ''
            this.getInstitutionList()
            break;
          case 'institutionList':
            if (this.institutionItem === index) {
              this.institutionItem = ''
            } else {
              this.institutionItem = index
            }
            this.priceItem = ''
            this.getInstitutionList()
            break;
          case 'priceList':
            if (this.priceItem === item.name) {
              this.priceItem = ''
            } else {
              this.priceItem = item.name
            }
            break;
          case 'timeList':
            if (this.timeItem === item) {
              this.timeItem = ''
            } else {
              this.timeItem = item
            }
            break;
          case 'materialsList':
            if (this.materialsItem === item.name) {
              this.materialsItem = ''
            } else {
              this.materialsItem = item.name
            }
            break;
          default:
            break;
        }
        this.getProjectPriceInfoEsPriceInfo()
      }
    }
  }
</script>

<style scoped lang="less">
  .main {
    text-align: left;
    .main_box {
      .main_form {
        padding: 20px;
        margin-bottom: 20px;
        border: 1px solid #E4EAF2;
        .main_form_list {
          .main_form_list_item {
            align-items: flex-start;
            padding-bottom: 20px;
            .main_form_list_item_title {
              padding-right: 10px;
              font-size: 16px;
              line-height: 32px;
              color: #4E4E4E;
            }
            .main_form_list_item_center {
              flex: 1;
              /deep/ .el-cascader, /deep/.el-select {
                width: 100%;
              }
              .main_form_list_item_center_margin {
                flex-wrap: wrap;
                margin: 0 -11px;
                .main_form_sort_item {
                  height: 32px;
                  line-height: 32px;
                  padding: 0 8px;
                  margin: 0 11px;
                  cursor: pointer;
                }
                .main_form_sort_item_on {
                  background-color: #EDF4FF;
                  border-radius: 4px;
                  color: #1890FF;
                }
                .main_form_list_item_center_margin_item {
                  height: 32px;
                  line-height: 32px;
                  padding: 0 8px;
                  margin: 5px 13px;
                  cursor: pointer;
                  background-color: #F6F7FB;
                  color: #4E4E4E;
                }
                .main_form_list_item_center_margin_item:hover {
                  background-color: #EDF4FF;
                }
              }
            }
          }
        }
      }
      .main_table {
        border: 1px solid #DCE5F2;
        .main_table_item {
          padding: 20px;
          justify-content: space-between;
          border-bottom: 1px solid #DCE5F2;
          .main_table_item_l {
            color: #4E4E4E;
            .main_table_item_l_title {
              padding-bottom: 20px;
              font-size: 16px;
              font-weight: 500;
            }
            > p {
              font-size: 14px;
            }
          }
          .main_table_item_r {
            font-size: 16px;
            font-weight: 500;
            color: #1B5C98;
            cursor: pointer;
          }
        }
      }
      .main_pagination {
        justify-content: flex-end;
        padding: 20px 0;
      }
    }
  }
</style>
